import React, { useEffect, useState } from 'react';
import './Tokenomics.css';
import playAudio from '../playAudio';
import Marquee from 'react-fast-marquee';

const Tokenomics = () => {

  const [showSparks, setShowSparks] = useState(false);

  React.useEffect(() => {
    playAudio();
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      setShowSparks(true);
      setTimeout(() => {
        setShowSparks(false);
      }, 2000); // Show sparks for 1 second
    }, 5000); // Trigger every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container-center-horizontal">
      <form className="tokenomics screen" name="form18" action="form18" method="post">
        <div className="overlap-group1 overlap">
          <img className="the-last-fax-bg-1-1" src="img/the-last-fax-bg---1-1.png" alt="The last Fax BG - 1 1" />
          <div className="overlap-group overlap">
            <img className="background-paper" src="img/background-paper.png" alt="Background Paper" />
            <img className="the-last-fax-1" src="img/the-last-fax-1.png" alt="The last Fax 1" />
            <img className="blank-page" src="img/background-paper.png" alt="Blank page" />
            <img className="tokenomics-1 animate-enter3" src="img/tokenomics-1.png" alt="tokenomics 1" />
          </div>
          <img className="printer-1" src="img/printer-1.png" alt="printer 1" />
          <a href="https://dexscreener.com/solana/dx9z6ztemu1rquxzyznyd2ecsbrb8zw7bk7hkswpulg7" target="_blank" rel="noopener noreferrer">
            <img className="graph-button" src="img/graph-button-1.svg" alt="graph-button" />
          </a>
          <a href="https://dexscreener.com/solana/dx9z6ztemu1rquxzyznyd2ecsbrb8zw7bk7hkswpulg7" target="_blank" rel="noopener noreferrer">
            <img className="face-button" src="img/face-button-1.svg" alt="face-button" />
          </a>
          <a href="https://x.com/thelastfax?s=21" target="_blank" rel="noopener noreferrer">
            <img className="x-button" src="img/x-button.svg" alt="x-button" />
          </a>
          <a href="https://t.me/TheLastFax" target="_blank" rel="noopener noreferrer">
            <img className="telegram-button" src="img/telegram-button-1.svg" alt="telegram-button" />
          </a>
          <a href="/lastfax"><img className="the-last-fax-button" src="img/the-last-fax-button.svg" alt="The last fax button" /></a>
          <a href="https://app.uniswap.org/" target="_blank" rel="noopener noreferrer">
            <img className="buy-button" src="img/buy-button.svg" alt="Buy button" />
          </a>
          <a href="/live-figures"><img className="live-figures-button" src="img/live-figures-button.svg" alt="live figures button" /></a>
          <a href="/tokenomics"><img className="tokenomics-button" src="img/tokenomics-button.svg" alt="tokenomics button" /></a>
          <div className="greenlight"></div>
          <div className="redlight"></div>
          {(
            <Marquee
              speed={100}
              gradient={false}
              className="smokey"
            >
              <img className="smokey-image" src="img/smokey.png" alt="Overlay" />
            </Marquee>
          )}

            {/* Sparks Image */}
              {showSparks && (
            <img className="sparks-image" src="img/sparks.gif" alt="Sparks" />
          )}
        </div>
      </form>
    </div>
  );
};


export default Tokenomics;
