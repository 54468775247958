const playAudio = () => {
  const audio = new Audio('printer.mp3');

  // Attempt to play the audio, without creating any buttons
  audio.play().catch((error) => {
    console.log('Failed to play audio:', error);
    // You can handle the autoplay restriction here, like showing a manual play button
  });
};

export default playAudio;
