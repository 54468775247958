import React, { useEffect, useState } from 'react';
import './LiveFigures.css';
import playAudio from '../playAudio';
import Marquee from 'react-fast-marquee';

const LiveFigures = () => {
  const [priceUsd, setPriceUsd] = useState(null);
  const [mcap, setMcap] = useState(null);
  const [txns, setTxns] = useState(null);
  const [showSparks, setShowSparks] = useState(false);

  React.useEffect(() => {
    playAudio();
  }, []);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch('https://api.dexscreener.com/latest/dex/tokens/0x6982508145454Ce325dDbE47a25d4ec3d2311933');
        const data = await response.json();
        const price = data.pairs[0].priceUsd;
        const mcaps = data.pairs[0].fdv;
        const txns1 = data.pairs[0].txns.h24.buys + data.pairs[0].txns.h24.sells;

        const formattedMcap = formatNumber(mcaps);

        setMcap(formattedMcap);
        setPriceUsd(price);
        setTxns(txns1);
        console.log("response", data.pairs[0].txns.h24.buys);
      } catch (error) {
        console.error('Error fetching price:', error);
      }
    };

    fetchPrice();
  }, []);

  const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(2) + 'B';
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(2) + 'M';
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(2) + 'K';
    } else {
      return num.toString();
    }
  };

  // Handle sparks effect
  useEffect(() => {
    const interval = setInterval(() => {
      setShowSparks(true);
      setTimeout(() => {
        setShowSparks(false);
      }, 2000); // Show sparks for 2 seconds
    }, 5000); // Trigger every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container-center-horizontal">
      <form className="live-figures screen" name="form3" action="form3" method="post">
        <div className="overlap-group1 overlap">
          <img className="the-last-fax-bg-1-1" src="img/the-last-fax-bg---1-1.png" alt="The last Fax BG - 1 1" />
          <div className="overlap-group overlap">
            <div className="background-paper" src="img/background-paper.png" alt="Background Paper with Live Images" />
            <div className="tokenomics-1" src="img/tokenomics-1.png" alt="tokenomics 1" />
            <div className="the-last-fax-1" src="img/the-last-fax-1.png" alt="The last Fax 1" />
            <img className="blank-page" src="img/background-paper.png" alt="Blank page" />
            <div className="blank-page-2 animate-enter" src="img/background-paper.png" alt="Blank page 2">
              <div className="page-content2">
                <div className="image-text">
                  <p className="text-1">========== FAX ==========</p>
                  <p className="text-2">LIVE FIGURES</p>
                  <p className="text-2">{priceUsd ? `CURRENT PRICE: $${priceUsd}` : 'Loading...'}</p>
                  <p className="text-2">{mcap ? `MCAP $${mcap}` : 'Loading...'}</p>
                  <p className="text-2">{txns ? `TXNS: ${txns} (24h)` : 'Loading...'}</p>
                </div>
              </div>
            </div>
          </div>
          <img className="printer-1" src="img/printer-1.png" alt="printer 1" />

          <a href="https://etherscan.io/" target="_blank" rel="noopener noreferrer" >
            <img className="graph-button" src="img/graph-button-1.svg" alt="graph-button" />
          </a>
          <a href="https://dexscreener.com/solana/dx9z6ztemu1rquxzyznyd2ecsbrb8zw7bk7hkswpulg7" target="_blank" rel="noopener noreferrer" >
            <img className="face-button" src="img/face-button-1.svg" alt="face-button" />
          </a>
          <a href="https://x.com/thelastfax?s=21" target="_blank" rel="noopener noreferrer" >
            <img className="x-button" src="img/x-button.svg" alt="x-button" />
          </a>
          <a href="https://t.me/TheLastFax" target="_blank" rel="noopener noreferrer" >
            <img className="telegram-button" src="img/telegram-button-1.svg" alt="telegram-button" />
          </a>
          <a href="/lastfax" ><img className="the-last-fax-button" src="img/the-last-fax-button.svg" alt="The last fax button" /></a>
          <a href="https://app.uniswap.org/" target="_blank" rel="noopener noreferrer">
  <img className="buy-button" src="img/buy-button.svg" alt="Buy button" />
</a>
          <a href="/live-figures" ><img className="live-figures-button" src="img/live-figures-button.svg" alt="live figures button" /></a>
          <a href="/tokenomics"><img className="tokenomics-button" src="img/tokenomics-button.svg" alt="tokenomics button" /></a>

          {/* Smoke Image */}
          {(
            <Marquee
              speed={100}
              className="smokey"
              autoFill={true}
            >
              <img className="smokey-image" src="img/smokey.png" alt="Overlay" />
            </Marquee>
          )}

          {/* Sparks Image */}
          {showSparks && (
            <img className="sparks-image" src="img/sparks.gif" alt="Sparks" />
          )}

          <div className="greenlight"></div>
          <div className="redlight"></div>
        </div>
      </form>
    </div>
  );
};

export default LiveFigures;
