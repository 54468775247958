import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './home/HomePage';
import LastFax from './lastFax/LastFax';
import Buy from './buy/Buy';
import LiveFigures from './liveFigures/LiveFigures';
import Tokenomics from './tokenomics/Tokenomics';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/lastfax" element={<LastFax />} />
        <Route path="https://app.uniswap.org/" element={<Buy />} />
        <Route path="/live-figures" element={<LiveFigures />} />
        <Route path="/tokenomics" element={<Tokenomics />} />
      </Routes>
    </Router>
  );
}

export default App;
